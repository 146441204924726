export const ADMIN_KEY = 'ADMIN';

export const SUPER_ADMIN_TENANT: Tenant = {
  id: ADMIN_KEY,
  name: 'Super Admin',
};

export interface CropArea {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface Overlay {
  id: string;
  name: string;
  url: string;
  width?: number;
  height?: number;
  cropArea?: CropArea;
  createdAt: Date;
}

export interface OverlayPreSignedUrl {
  name?: string;
  url: string;
  preSignedS3Url: string;
}

export interface TenantMetadata {
  useFlash?: boolean;
  overalays?: Overlay[];
  foldersConfig?: {
    fallback?: boolean;
    folders?: {
      folderName: string;
      numberOfPhotos: number;
      overlay?: string | null;
    }[];

    [key: string]: any;
  };

  [key: string]: any;
}

export interface Tenant {
  id: string;
  name: string;
  createdAt?: Date;
  metadata?: TenantMetadata;
}

export interface UserTenants {
  userId: string;
  tenants: Tenant[];
}

export interface UserTenant {
  userId: string;
  tenant: Tenant;
}

export interface TenantsResponseData {
  tenants: Tenant[];
  activeTenants?: Tenant[];
}
